import React, { useState } from "react";
import "./short.css";
import ButtonComponent from "../../../components/button";
import { handleCopyText, validateUrl } from "../../../components/function";
import NavInput from "../navInput";
import Notification from "../../../components/notification";

import { app } from "../../../components/firebase/firebase";

function Short() {
  const firebase = app.firestore();

  const urls = [
    {
      urlDefault: "http://localhost:3000/dock/short",
      urlChange: "https://url.loukaaa.com/Jkad8md0",
      date: "17/02/2024",
    },
    {
      urlDefault: "http://localhost:3000/dock/short",
      urlChange: "https://url.loukaaa.com/jdemm83",
      date: "17/02/2024",
    },
  ];

  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [copy, setCopy] = useState(false);

  // notification useState
  const [msg, setMsg] = useState("");
  const [state, setState] = useState(null);

  const handleChangeUrl = (text) => {
    if (text !== url) {
      setUrl(text);
      setCopy(false);
    }
  };

  const handleShort = async () => {
    setLoading(true);
    if (validateUrl(url)) {
      // fais une boucle qui vient créer un code d'url de 6 caractères et si il existet dans la collection short alors en refaire un
      var code = "";
      var exist = true;
      while (exist) {
        code = Math.random().toString(36).substr(2, 6);
        exist = (await firebase.collection("short").doc(code).get()).exists;
      }
      firebase
        .collection("short")
        .doc(code)
        .set({
          url: url,
          short: "https://url.loukaaa.com/" + code,
          date: new Date().toLocaleDateString(),
          now: new Date().getTime(),
        })
        .then(() => {
          setMsg("URL raccourcie : https://url.loukaaa.com/" + code);
          setUrl("https://url.loukaaa.com/" + code);
          setCopy(true);
          setState(true);
          setLoading(false);
        })
        .catch((err) => {
          setMsg("Une errueur est survenue");
          console.log(
            "Erreur lors de l'ajout de l'url dans la base de donnée : " + err
          );
          setState(false);
          setLoading(false);
        });
    } else {
      setMsg("Veuillez entrer une URL valide");
      console.log("Veuillez entrer une URL valide");
      setState(false);
    }
  };

  const handleCopy = (text) => {
    try {
      handleCopyText(text);
      setMsg("Text copié dans le presse-papier");
      setState(true);
    } catch (err) {
      setMsg("Une errueur est survenue");
      console.log("Erreur lors de l'impression dans le presse-papier : " + err);
      setState(false);
    }
  };

  return (
    <div className="Short PageDock">
      <Notification
        msg={msg}
        state={state}
        setState={(value) => setState(value)}
        setMsg={(value) => setMsg(value)}
      />
      <div className="wrapper">
        <NavInput
          placeholder="Entrer une url vidéo Youtube"
          value={url}
          setValue={(e) => handleChangeUrl(e)}
          onClick={() => (copy ? handleCopy(url) : handleShort())}
          btnText={copy ? "Copier" : "Résume"}
          loading={loading}
        />
        <div className="window">
          <div className="section">
            <h4>Historique</h4>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
          </div>
          {urls.map((u, index) => (
            <div className="section" key={index}>
              <span className="date">{u.date}</span>
              <div className="url-history">
                <p data-text={u.urlChange}>{u.urlDefault}</p>
                <ButtonComponent
                  onClick={() => handleCopy(u.urlChange)}
                  text="Copier"
                  style={{ padding: "8px 15px" }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Short;
