import React, { useState } from "react";
import { Link } from "react-router-dom";
import { listApp } from "../../components/function/var";

function ListIcon() {
  const [list, setList] = useState(listApp.slice(0, 7));

  const onDragStart = (e, index) => {
    e.dataTransfer.setData("index", index);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e, dropIndex) => {
    const dragIndex = e.dataTransfer.getData("index");
    const draggedItems = [...list];
    const [draggedItem] = draggedItems.splice(dragIndex, 1);
    draggedItems.splice(dropIndex, 0, draggedItem);
    setList(draggedItems);
  };

  return (
    <div className="list">
      {list.map((i, index) => (
        <Link
          to={i.link}
          className="item"
          key={index}
          draggable
          onDragStart={(e) => onDragStart(e, index)}
          onDragOver={onDragOver}
          onDrop={(e) => onDrop(e, index)}
        >
          <div className={i.src}></div>
        </Link>
      ))}
    </div>
  );
}

export default ListIcon;
