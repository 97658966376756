import React, { useState } from "react";
import "./accountSettings.css";
import ButtonComponent from "../../../components/button";
import { useNavigate } from "react-router-dom";
import InputComponent from "../../../components/input";

import { HiOutlineUpload } from "react-icons/hi";

import ImageDefault from "../../../assets/images/logouser.jpeg";

function AccountSettings() {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("Louka");
  const [lastName, setLastName] = useState("Mourrier");
  const [email, setEmail] = useState("louka.mourrier@gmail.com");

  const [imageUrl, setImageUrl] = useState(ImageDefault);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setImageUrl(url);
    }
  };

  const handleCancel = () => {
    navigate("/dock");
  };
  return (
    <div className="AccountSettings window">
      <div className="section">
        <h4>Compte</h4>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
      </div>
      <div className="section">
        <div className="header">
          <p className="sub-title">Photo de profile</p>
          <span>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</span>
        </div>
        <div className="input-profile">
          <img src={imageUrl} alt="" className="bg" />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageChange(e)}
            id="bgSettings"
          />
          <label className="upload" htmlFor="bgSettings">
            <HiOutlineUpload className="icon" />
          </label>
        </div>
      </div>
      <div className="section">
        <div className="header">
          <p className="sub-title">Information</p>
          <span>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</span>
        </div>
        <div className="list">
          <div className="item">
            <InputComponent
              placeholder="Prénom"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <InputComponent
              placeholder="Nom"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="item">
            <InputComponent
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="section">
        <div className="header">
          <p className="sub-title">Mot de passe</p>
          <span>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</span>
        </div>
        <div className="list">
          <div className="item">
            <ButtonComponent text="Réinitialiser le mot de passe" />
          </div>
        </div>
      </div>
      <div className="section buttons">
        <ButtonComponent skin="secondy" text="Reset" />
        <div className="ctn-btn">
          <ButtonComponent
            skin="secondy"
            text="Annuler"
            onClick={() => handleCancel()}
          />
          <ButtonComponent text="Sauvegarder" />
        </div>
      </div>
    </div>
  );
}

export default AccountSettings;
