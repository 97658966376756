import React, { useEffect, useRef, useState } from "react";
import "./dock.css";
import ButtonComponent from "../../components/button";

// icons
import { RiLink } from "react-icons/ri";

function NavInput({ placeholder, onClick, setValue, value, btnText, loading }) {
  const [text, setText] = useState(value);
  const inputUrl = useRef(null);

  const [isLoading, setisLoading] = useState(false);

  const [isFocused, setIsFocused] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  // const handle
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const handleLoading = (state) => [setisLoading(state), setIsDisabled(state)];

  const handleChangeText = (text) => {
    setValue(text);
    setText(text);
  };

  // useEffect
  useEffect(() => {
    inputUrl.current.focus();
    handleFocus();
  }, []);

  useEffect(() => {
    handleLoading(loading);
    handleChangeText(value);
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && isFocused) {
        handleClick();
      }
    };

    const inputElement = inputUrl.current;
    window.addEventListener("keydown", handleKeyDown);
    inputElement.addEventListener("focus", handleFocus);
    inputElement.addEventListener("blur", handleBlur);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      inputElement.removeEventListener("focus", handleFocus);
      inputElement.removeEventListener("blur", handleBlur);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, text, loading, value]);

  const handleClick = () => {
    onClick();
    handleLoading();
  };

  return (
    <div className="nav-input">
      <div className="url-input">
        <div>
          <RiLink className="icon" />
          <input
            disabled={isDisabled}
            ref={inputUrl}
            type="text"
            placeholder={placeholder}
            value={text}
            onChange={(e) => handleChangeText(e.target.value)}
          />
        </div>
        <ButtonComponent
          text={btnText}
          style={{ padding: "8px 15px" }}
          onClick={() => handleClick()}
          loading={isLoading}
        />
      </div>
    </div>
  );
}

export default NavInput;
