const api = "https://us-central1-aksoa-company.cloudfunctions.net/api";
// const api = "http://localhost:3008";

var errorMsg =
  "Une erreur s'est produite lors de la récupération des données, Réessayer plus tard...";

const listApp = [
  { id: 7, src: "dock-icon-tel", link: "notification", name: "Notification" },
  { id: 13, src: "dock-icon-settings", link: "settings", name: "Settings" },
  { id: 10, src: "dock-icon-firefox", link: "/", name: "Firefox" },
  { id: 2, src: "dock-icon-app", link: "news", name: "News" },
  { id: 6, src: "dock-icon-outlook", link: "short", name: "Short" },
  { id: 13, src: "dock-icon-vlc", link: "qr", name: "QR Code" },
  { id: 11, src: "dock-icon-netflix", link: "vishort", name: "Vishort" },
  { id: 1, src: "dock-icon-finder", link: "/", name: "Finder" },
  { id: 4, src: "dock-icon-message", link: "/", name: "Message" },
  { id: 5, src: "dock-icon-mail", link: "/", name: "Mail" },
  { id: 8, src: "dock-icon-vs", link: "/", name: "VS Code" },
  { id: 9, src: "dock-icon-terminal", link: "/", name: "Terminal" },
  { id: 12, src: "dock-icon-notion", link: "/", name: "Notion" },
  { id: 11, src: "dock-icon-maps", link: "/", name: "Maps" },
  { id: 12, src: "dock-icon-drive", link: "/", name: "Drive" },
];

export { api, errorMsg, listApp };
