import React, { useState } from "react";
import "./appearanceSettings.css";
import ButtonComponent from "../../../components/button";
import { useNavigate } from "react-router-dom";

import { HiOutlineUpload } from "react-icons/hi";

import ImageDefault from "../../../assets/images/bg-dock2.png";

function AppearanceSettings() {
  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(0);
  const [imageUrl, setImageUrl] = useState(ImageDefault);

  const items = ["default", "purple", "red", "yellow", "green"];

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setImageUrl(url);
    }
  };

  const handleCancel = () => {
    navigate("/dock");
  };
  return (
    <div className="AppearanceSettings window">
      <div className="section">
        <h4>Apparence</h4>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
      </div>
      <div className="section">
        <div className="header">
          <p className="sub-title">
            Couleur <span>Bêta</span>
          </p>
          <span>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</span>
        </div>
        <div className="radio">
          {items.map((item, index) => (
            <div
              key={index}
              className={
                activeIndex === index
                  ? "item active c-" + items[index]
                  : "item c-" + items[index]
              }
              onClick={() => handleClick(index)}
            ></div>
          ))}
        </div>
      </div>
      <div className="section">
        <div className="header">
          <p className="sub-title">Interface theme</p>
          <span>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</span>
        </div>
        <div className="input-bg">
          <img src={imageUrl} alt="" className="bg" />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageChange(e)}
            id="bgSettings"
          />
          <label className="upload" htmlFor="bgSettings">
            <HiOutlineUpload className="icon" />
          </label>
        </div>
      </div>
      <div className="section buttons">
        <ButtonComponent skin="secondy" text="Reset" />
        <div className="ctn-btn">
          <ButtonComponent
            skin="secondy"
            text="Annuler"
            onClick={() => handleCancel()}
          />
          <ButtonComponent text="Sauvegarder" />
        </div>
      </div>
    </div>
  );
}

export default AppearanceSettings;
