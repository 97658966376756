import React, { useEffect, useRef, useState } from "react";
import "./vishort.css";
import axios from "axios";
import YouTube from "react-youtube";
import { MessageToJSON } from "../../../components/function/MessageToJson";
import { formatTime, getYouTubeVideoId } from "../../../components/function";
import NavInput from "../navInput";
import Notification from "../../../components/notification";
import { RiSearch2Line } from "react-icons/ri";
import ButtonComponent from "../../../components/button";

const Vishort = () => {
  // useState
  const [url, setUrl] = useState("");
  const [timeCode, setTimeCode] = useState([]);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [response, setResponse] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);

  // notification useState
  const [msg, setMsg] = useState("");
  const [state, setState] = useState(null);

  const [isFocused, setIsFocused] = useState(false);

  // useRef
  const playerRef = useRef(null);
  const inputSearch = useRef(null);

  // const handle
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  // const videoPlayer
  const onReady = (event) => {
    // Stocke le lecteur YouTube dans la référence
    playerRef.current = event.target;
  };

  const handleClick = (time) => {
    if (playerRef.current) {
      playerRef.current.seekTo(time); // 3 minutes et 12 secondes = 3*60 + 12 = 192 secondes
    } else {
      setMsg("Une errueur est survenue");
      console.log("Une errueur est survenue");
      setState(false);
    }
  };

  useEffect(() => {
    if (url !== "") {
      const handleKeyDown = (event) => {
        if (event.key === "Enter" && isFocused) handleSearch();
      };
      const inputElement = inputSearch.current;
      window.addEventListener("keydown", handleKeyDown);
      inputElement.addEventListener("focus", handleFocus);
      inputElement.addEventListener("blur", handleBlur);

      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
        inputElement.removeEventListener("focus", handleFocus);
        inputElement.removeEventListener("blur", handleBlur);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  // const proccess time code
  const handleProcess = () => {
    setLoading(true);
    axios
      .post("http://localhost:4000/process", { url })
      .then((response) => {
        setTimeCode(MessageToJSON(response.data.content));
        setLoading(false);
      })
      .catch((err) => {
        setMsg("Une erreur est survenue");
        console.log("Une erreur est survenue : " + err);
        setState(false);
        setLoading(false);
      });
  };

  // const proccess time code
  const handleSearch = () => {
    setLoadingSearch(true);
    console.log(search);
    axios
      .post("http://localhost:4000/process/search", { url, search })
      .then((response) => {
        setResponse(response.data.content);
        setLoadingSearch(false);
      })
      .catch((err) => {
        setMsg("Une erreur est survenue");
        console.log("Une erreur est survenue : " + err);
        setState(false);
        setLoadingSearch(false);
      });
  };

  return (
    <div className="Vishort PageDock">
      <Notification
        msg={msg}
        state={state}
        setState={(value) => setState(value)}
        setMsg={(value) => setMsg(value)}
      />
      <div className="wrapper">
        <NavInput
          placeholder="Entrer une url vidéo Youtube"
          value={url}
          setValue={(e) => setUrl(e)}
          onClick={() => handleProcess()}
          btnText="Résume"
          loading={loading}
        />
        {timeCode.length !== 0 && (
          <div className="flex-window">
            <div className="window">
              <div className="section">
                <h4>Time Code</h4>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
              </div>
              <div className="section">
                <YouTube videoId={getYouTubeVideoId(url)} onReady={onReady} />
              </div>
              <div className="section">
                {timeCode.map((m, index) => (
                  <div
                    className="item"
                    key={index}
                    onClick={() => handleClick(m.startTimeCode)}
                  >
                    <p className="time-code">
                      {formatTime(m.startTimeCode)} -{" "}
                      {formatTime(m.endTimeCode)}
                    </p>
                    <p>{m.titlePart}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="window">
              <div className="section">
                <h4>Recherche dans la video</h4>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
              </div>
              <div className="section">
                <div className="search">
                  <RiSearch2Line className="icon" />
                  <input
                    type="text"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    placeholder="Rechercher dans la vidéo"
                    ref={inputSearch}
                  />
                  <ButtonComponent
                    text="Rechercher"
                    style={{ padding: "8px 15px" }}
                    onClick={() => handleSearch()}
                    loading={loadingSearch}
                  />
                </div>
              </div>
              {response !== "" && (
                <div className="section">
                  <p className="ctn-search-result">{response}</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Vishort;
