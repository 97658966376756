import React from "react";
import "./dock.css";
import ListIcon from "./listIcon";

function Dock() {
  return (
    <div className="Dock">
      <ListIcon />
    </div>
  );
}

export default Dock;
