import React, { useEffect, useState } from "react";
import "./news.css";

function News() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    setNews([
      {
        name: "Finder",
        icon: "dock-icon-finder",
        title: "Reviens on a besoin de toi",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
        date: "il y a 7min",
        link: "/",
      },
    ]);
  }, []);

  return (
    <div className="News PageDock">
      <div className="wrapper">
        <div className="window">
          {news.map((n, index) => (
            <div className="section" key={index}>
              <div className="icon-app dock-icon-finder"></div>
              <div>
                <div className="flex">
                  <h5 className="title">{n.name}</h5>
                  <span className="date">{n.date}</span>
                </div>
                <p className="description">{n.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default News;
