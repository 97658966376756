import React, { useState } from "react";
import "./settings.css";
import NotificationSettings from "./notificationSettings";
import AppearanceSettings from "./appearanceSettings";
import SubscriptionSettings from "./subscriptionSettings";
import AccountSettings from "./accountSettings";

function Settings() {
  const [activeIndex, setActiveIndex] = useState(0);

  const items = ["Apparence", "Notification", "Abonnement", "Compte"];

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="Settings PageDock">
      <div className="wrapper">
        <div className="nav">
          <ul>
            {items.map((item, index) => (
              <li
                key={index}
                className={activeIndex === index ? "active" : ""}
                onClick={() => handleClick(index)}
              >
                <span>{item}</span>
              </li>
            ))}
          </ul>
        </div>
        {activeIndex === 0 ? (
          <AppearanceSettings />
        ) : activeIndex === 1 ? (
          <NotificationSettings />
        ) : activeIndex === 2 ? (
          <SubscriptionSettings />
        ) : (
          <AccountSettings />
        )}
      </div>
    </div>
  );
}

export default Settings;
