import React, { useEffect, useRef, useState } from "react";
import "./nav.css";
import ButtonComponent from "../button";

import LogoActive from "../../assets/images/logo-active.png";
import LogoBlack from "../../assets/images/logo-black.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { scrollTo } from "../function";

const Nav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [active, setActive] = useState(false);
  const [resp, setResp] = useState(false);
  const [home, setHome] = useState(false);

  const navRef = useRef(null);

  const item = [
    { name: "Accueil", link: "home" },
    { name: "Compétences", link: "features" },
    { name: "Projets", link: "projects" },
    { name: "Contact", link: "contact" },
  ];

  useEffect(() => {
    window.addEventListener("scroll", function (e) {
      var scrollPosition = window.scrollY;
      setResp(false);
      if (scrollPosition >= 10) {
        setActive(true);
      } else {
        setActive(false);
      }
    });

    location.pathname !== "/" ? setHome(true) : setHome(false);

    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target) && resp) {
        setResp(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [location.pathname, resp]);

  const handleClick = () => {
    setResp(!resp);
  };

  return (
    <nav
      className={`active-${active} responsive-${resp} home-${home}`}
      ref={navRef}
    >
      <div className="wrapper">
        <div className="flex">
          <div className="left">
            <label onClick={() => handleClick()} className="menu-burger">
              <span></span>
              <span></span>
              <span></span>
            </label>
            <Link to="/">
              <img
                src={active || resp || home ? LogoBlack : LogoActive}
                alt="Logo"
                className="logo-nav"
              />
            </Link>
          </div>
          <div className="right">
            <ul className="list">
              {item.map((item, index) => (
                <li className="item" key={index}>
                  {home ? (
                    <Link to={`/#${item.link}`}>{item.name}</Link>
                  ) : (
                    <button onClick={() => scrollTo(item.link)}>
                      {item.name}
                    </button>
                  )}
                </li>
              ))}
            </ul>
            <ButtonComponent
              text="Démarrer"
              skin={!(active || resp || home) && "white"}
              onClick={() => navigate("/estimate")}
            />
          </div>
        </div>
        <div className="menu">
          <ul className={`list ${resp ? "active" : ""}`}>
            {item.map((item, index) => (
              <li className="item" key={index}>
                {home ? (
                  <Link to={`/#${item.link}`}>{item.name}</Link>
                ) : (
                  <button onClick={() => scrollTo(item.link)}>
                    {item.name}
                  </button>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
