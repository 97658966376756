import React, { useEffect, useState } from "react";
import "./notificationSettings.css";
import ButtonComponent from "../../../components/button";
import Toggle from "../../../components/input/toggle";
import { useNavigate } from "react-router-dom";

function NotificationSettings() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [actualite, setActualite] = useState(false);
  const [promotion, setPromotion] = useState(false);

  useEffect(() => {
    setEmail("louka.mourrier@gmail.com");
    setActualite(true);
    setPromotion(false);
  }, []);

  const handleCancel = () => {
    navigate("/dock");
  };
  return (
    <div className="NotificationSettings window">
      <div className="section">
        <h4>Notification</h4>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
      </div>
      <div className="section">
        <div className="header">
          <p className="sub-title">Email</p>
          <span>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</span>
        </div>
        <div className="list">
          <div className="item">
            <p className="email">{email}</p>
          </div>
          <div className="item">
            <p>Actualités</p>
            <Toggle
              value={actualite}
              onChange={(e) => setActualite(e.target.checked)}
            />
          </div>
          <div className="item">
            <p>Promotions, offres spéciales</p>
            <Toggle
              value={promotion}
              onChange={(e) => setPromotion(e.target.checked)}
            />
          </div>
        </div>
      </div>
      <div className="section buttons">
        <ButtonComponent skin="secondy" text="Reset" />
        <div className="ctn-btn">
          <ButtonComponent
            skin="secondy"
            text="Annuler"
            onClick={() => handleCancel()}
          />
          <ButtonComponent text="Sauvegarder" />
        </div>
      </div>
    </div>
  );
}

export default NotificationSettings;
