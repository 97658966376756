import React from "react";
import "./alertSmall.css";

import {
  BsInfoCircleFill,
  BsFillCheckCircleFill,
  BsExclamationTriangleFill,
} from "react-icons/bs";

function AlertSmall({ type, children, style }) {
  return (
    <div className={"AlertSmall " + type} style={style}>
      <div className="ctn-icon">
        {type === "warn" ? (
          <BsInfoCircleFill className="icon" />
        ) : type === "info" ? (
          <BsInfoCircleFill className="icon" />
        ) : type === "error" ? (
          <BsExclamationTriangleFill className="icon" />
        ) : (
          type === "success" && <BsFillCheckCircleFill className="icon" />
        )}
      </div>
      <div className="ctn-text">{children}</div>
    </div>
  );
}

export default AlertSmall;
