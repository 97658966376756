export function MessageToJSON(message) {
  // Split the message by newline character
  const segments = message.split("\n");

  // Initialize an array to store the JSON objects
  const jsonData = [];

  // Regular expression to match the pattern
  const regex =
    /startTimeCode=\s*(\d+),\s*endTimeCode=\s*(\d+),\s*titlePart=\s*(.+)/;

  // Iterate over each segment to extract startTimeCode, endTimeCode, and titlePart
  segments.forEach((segment) => {
    const match = segment.match(regex);

    if (match) {
      const startTimeCode = parseInt(match[1], 10);
      const endTimeCode = parseInt(match[2], 10);
      const titlePart = match[3].trim();

      // Construct JSON object
      const segmentObj = {
        startTimeCode,
        endTimeCode,
        titlePart,
      };

      // Push object to jsonData array
      jsonData.push(segmentObj);
    }
  });

  return jsonData;
}
