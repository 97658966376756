import React, { useEffect, useRef, useState } from "react";
import "./dockBar.css";
import jsonp from "jsonp";
import { listApp } from "../../function/var";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Logo from "../../../assets/images/logo-active.png";
import LogoUser from "../../../assets/images/logouser.jpeg";

import { RiArrowRightUpLine, RiSearch2Line } from "react-icons/ri";

import IconFirefox from "../../../assets/images/icons/iconFirefox.png";
import IconMail from "../../../assets/images/icons/iconMail.png";

function DockBar() {
  const inputRef = useRef(null);
  const navDockRef = useRef(null);

  const [result, setResult] = useState(false);
  const [active, setActive] = useState(false);
  const [search, setSearch] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [appSearch, setAppSearch] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    document.querySelector("body").style.overflow = "hidden";

    location.pathname === "/dock"
      ? document.getElementById("navDock").classList.remove("path")
      : document.getElementById("navDock").classList.add("path");

    const handleKeyDown = (event) => {
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
      if (
        (isMac && event.metaKey && event.key === "k") ||
        (!isMac && event.ctrlKey && event.key === "k")
      )
        handleActive(event);
      else if (event.key === "Escape") handleClose();

      if (
        (isMac && event.metaKey && event.key === "j") ||
        (!isMac && event.ctrlKey && event.key === "j")
      )
        if (location.pathname !== "/dock") {
          navigate("/dock");
          handleClose();
        }
    };

    const handleClickOutside = (event) => {
      if (navDockRef.current && !navDockRef.current.contains(event.target))
        handleClose();
    };

    window.addEventListener("keydown", handleKeyDown);
    document.addEventListener("click", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      document.addEventListener("click", handleClickOutside);
    };
  }, [location, navigate]);

  const handleActive = (event) => {
    event.preventDefault();
    document.getElementById("navDock").classList.toggle("active");
    inputRef.current.focus();
    setActive((a) => !a);
  };
  const handleClose = () => {
    document.getElementById("navDock").classList.remove("active");
    inputRef.current.blur();
    setActive(false);
  };

  const handleSearch = (text) => {
    setSearch(text);
    setResult(text !== "");
    text !== ""
      ? document.getElementById("navDock").classList.add("result")
      : document.getElementById("navDock").classList.remove("result");
    if (search.length > 2) {
      fetchSuggestions();
    } else {
      setSuggestions([]);
    }

    var index = listApp.findIndex(
      (item) => item.name.toLocaleLowerCase() === text.toLocaleLowerCase()
    );
    setAppSearch(index >= 0 ? listApp[index] : null);
  };

  const fetchSuggestions = async () => {
    const url = `https://suggestqueries.google.com/complete/search?client=firefox&q=${search}`;
    jsonp(url, null, (err, data) => {
      if (err) {
        console.error("Error fetching suggestions:", err);
      } else {
        if (data[1][0] === search) data[1].shift();
        setSuggestions(data[1].slice(0, 3));
      }
    });
  };

  const searchAppNameWithLink = (link) => {
    var index = listApp.findIndex((item) => "/dock/" + item.link === link);
    return index;
  };

  const handleClickApp = (link) => {
    navigate(link);
    handleClose();
  };

  return (
    <div
      className="DockBar"
      id="navDock"
      ref={navDockRef}
      onClick={(e) => !active && handleActive(e)}
    >
      <div className="top">
        {location.pathname === "/dock" ? (
          <img className="logo" src={Logo} alt="logo black" />
        ) : (
          <Link to="/dock" className="logo h4">
            {searchAppNameWithLink(location.pathname) >= 0
              ? listApp[searchAppNameWithLink(location.pathname)].name
              : "404"}
          </Link>
        )}
        <div className="search">
          <RiSearch2Line className="icon" />
          <input
            type="text"
            placeholder="Recherche"
            ref={inputRef}
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <img className="account" src={LogoUser} alt="logo user" />
      </div>
      {active && result && (
        <>
          {appSearch && (
            <div className="result app">
              <div
                className="item"
                onClick={() => handleClickApp(appSearch.link)}
              >
                <div className="content">
                  <div className={appSearch.src}></div>
                  <p>{appSearch.name}</p>
                </div>
                <div className="button">Ouvrir</div>
              </div>
            </div>
          )}
          <div className="result">
            <a href="/" className="item">
              <div className="content">
                <img src={IconFirefox} alt="icon" />
                <p>{search}</p>
              </div>
              <RiArrowRightUpLine className="icon" />
            </a>
            {suggestions.map((s, index) => (
              <a
                href={"https://www.google.com/search?q=" + s}
                key={index}
                className="item"
              >
                <div>
                  <img src={IconFirefox} alt="icon" />
                  <div>
                    <p>{s}</p>
                  </div>
                </div>
                <RiArrowRightUpLine className="icon" />
              </a>
            ))}
            <a href="/" className="item">
              <div className="content">
                <img src={IconMail} alt="icon" />
                <p>Je ne suis pas un bleu</p>
              </div>
              <RiArrowRightUpLine className="icon" />
            </a>
          </div>
        </>
      )}
    </div>
  );
}

export default DockBar;
