import React, { useEffect, useState } from "react";
import "./notification.css";

// icons
import { TbFaceIdError, TbSquareRoundedCheckFilled } from "react-icons/tb";

function Notification({ state, setState, msg, setMsg }) {
  const [isState, setIsState] = useState(null);
  const [isMsg, setIsMsg] = useState("");

  useEffect(() => {
    if (msg !== isMsg && state !== null) {
      active();
    }

    if (state !== null) {
      active(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMsg, isState, msg, setMsg, state]);

  const active = (state) => {
    setIsMsg(msg);
    setState(state);
    setIsState(state);
    setTimeout(() => {
      setTimeout(() => {
        setMsg("");
        setState(null);
        setIsState(null);
      }, 200);
    }, 3000);
  };
  return (
    <div className={"Notification " + isState}>
      <div className="container">
        {isState ? (
          <TbSquareRoundedCheckFilled className="icon" />
        ) : (
          <TbFaceIdError className="icon" />
        )}
        {isMsg || "L'opération est un succés"}
      </div>
    </div>
  );
}

export default Notification;
