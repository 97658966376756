import React, { useRef, useState } from "react";
import "./qr.css";
import { toPng } from "html-to-image";
import ButtonComponent from "../../../components/button";
import { QRCodeSVG } from "qrcode.react";
import InputComponent from "../../../components/input";
import { validateUrl } from "../../../components/function";
import NavInput from "../navInput";
import Notification from "../../../components/notification";

function Qr() {
  const [url, setUrl] = useState("");
  const [qrCodeValue, setQrCodeValue] = useState("");
  const qrCodeRef = useRef();
  const [loading, setLoading] = useState(false);

  const [fgColor, setFgColor] = useState("#ffffff");
  const [bgColor, setBgColor] = useState("#000000");
  const [urlImage, setUrlImage] = useState("");
  const [widthImage, setWidthImage] = useState();
  const [heightImage, setHeightImage] = useState();

  // notification useState
  const [msg, setMsg] = useState("");
  const [state, setState] = useState(null);

  const handleGenerateQRCode = () => {
    try {
      setQrCodeValue(url);
    } catch (err) {
      setMsg("Une erreur est survenue");
      console.log("Une erreur est survenue : " + err);
      setState(false);
    }
  };

  const handleDownloadQRCode = async () => {
    setLoading(true);
    if (validateUrl && qrCodeRef.current) {
      toPng(qrCodeRef.current)
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "qrcode.png";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setLoading(false);
        })
        .catch((err) => {
          setMsg("Erreur lors du téléchargement du QR code");
          console.error("Erreur lors du téléchargement du QR code :", err);
          setState(false);
          setLoading(false);
        });
    } else {
      setMsg("Veuillez entrer une URL valide");
      console.log("Veuillez entrer une URL valide");
      setState(false);
    }
  };

  return (
    <div className="Qr PageDock">
      <Notification
        msg={msg}
        state={state}
        setState={(value) => setState(value)}
        setMsg={(value) => setMsg(value)}
      />
      <div className="wrapper">
        <NavInput
          placeholder="Text en QR Code"
          value={url}
          setValue={(e) => setUrl(e)}
          onClick={() => handleGenerateQRCode()}
          btnText="Résume"
        />
        {qrCodeValue && (
          <div className="window">
            <div className="section header">
              <h4>Paramètre du QR Code</h4>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
            </div>
            <div className="section">
              <div className="flex">
                <input
                  type="color"
                  id="fg-color"
                  className="input-color"
                  value={fgColor}
                  onChange={(e) => setFgColor(e.target.value)}
                />
                <input
                  type="color"
                  id="bg-color"
                  className="input-color"
                  value={bgColor}
                  onChange={(e) => setBgColor(e.target.value)}
                />
                <div className="two-row color-ctn">
                  <label
                    htmlFor="fg-color"
                    style={{ backgroundColor: fgColor }}
                  ></label>
                  <label
                    htmlFor="bg-color"
                    style={{ backgroundColor: bgColor }}
                  ></label>
                </div>
                <InputComponent
                  placeholder="Url Image"
                  value={urlImage}
                  onChange={(e) => setUrlImage(e.target.value)}
                />
                <div className="two-row">
                  <InputComponent
                    placeholder="Width"
                    value={widthImage}
                    onChange={(e) => setWidthImage(e.target.value)}
                  />
                  <InputComponent
                    placeholder="Height"
                    value={heightImage}
                    onChange={(e) => setHeightImage(e.target.value)}
                  />
                </div>
                <ButtonComponent
                  text="Télécharger"
                  loading={loading}
                  onClick={() => handleDownloadQRCode()}
                />
              </div>
              <div className="ctn-qr" ref={qrCodeRef}>
                {urlImage ? (
                  <QRCodeSVG
                    value={qrCodeValue}
                    className="qr-code"
                    size={128}
                    bgColor={bgColor}
                    fgColor={fgColor}
                    level={"Q"}
                    includeMargin={true}
                    imageSettings={{
                      src: urlImage,
                      x: undefined,
                      y: undefined,
                      height: heightImage,
                      width: widthImage,
                      excavate: true,
                    }}
                  />
                ) : (
                  <QRCodeSVG
                    value={qrCodeValue}
                    className="qr-code"
                    size={128}
                    bgColor={bgColor}
                    fgColor={fgColor}
                    level={"L"}
                    includeMargin={true}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Qr;
