// src/App.js
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { app } from "../firebase/firebase";

const db = app.firestore();
const Redirector = () => {
  const { code } = useParams();
  const [error, setError] = React.useState("");

  useEffect(() => {
    setError("");
    const fetchURL = async () => {
      if (code) {
        try {
          const doc = await db.collection("short").doc(code).get();
          if (doc.exists) {
            const url = doc.data().url;
            window.location.href = url; // Redirige vers l'URL longue
          } else {
            console.error("No such document!");
            setError("Une erreur est survenue");
          }
        } catch (error) {
          console.error("Error getting document:", error);
          setError("Une erreur est survenue");
        }
      }
    };

    fetchURL();
  }, [code]);

  return <div>{error || "Redirecting..."}</div>;
};

export default Redirector;
