import React from "react";
import "./toggle.css";

function Toggle({ value, onChange }) {
  return (
    <label className="Togggle">
      <input
        type="checkbox"
        value={value}
        onChange={onChange}
        checked={value}
      />
      <span className="slider round"></span>
    </label>
  );
}

export default Toggle;
