import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";

import Logo from "../../assets/images/logo-active.png";
import ImageBottomHeader from "../../assets/images/landing/header.svg";

import { scrollTo } from "../function";

function Footer() {
  const year = new Date().getFullYear();

  const item = [
    { name: "Accueil", link: "home" },
    { name: "Compétences", link: "features" },
    { name: "Projets", link: "projects" },
    { name: "Contact", link: "contact" },
  ];

  return (
    <div className="Footer">
      <img src={ImageBottomHeader} alt="" />
      <div className="inner">
        <div>
          <img src={Logo} alt="logo" />
          <p className="text-xs">
            Tous droits réservés &copy; {year} <br />
            Réalisé par loukaaa.com
          </p>
        </div>
        <div className="flex">
          <ul>
            <h5>Liens</h5>
            {item.map((item, index) => (
              <li className="item" key={index}>
                {window.location.href === "/" ? (
                  <Link to={`/#${item.link}`}>{item.name}</Link>
                ) : (
                  <button onClick={() => scrollTo(item.link)}>
                    {item.name}
                  </button>
                )}
              </li>
            ))}
          </ul>
          <ul>
            <h5>Légales</h5>
            <li>
              <Link>CGV</Link>
            </li>
            <li>
              <Link>Mention</Link>
            </li>
            <li>
              <Link>Cookie</Link>
            </li>
          </ul>
          <ul>
            <h5>Contact</h5>
            <li>
              <a href="//linkeding">Linkedin</a>
            </li>
            <li>
              <a href="mailto:contact@loukaaa.com">contact@loukaaa.com</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
